import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const AppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(() => {
			MenuService.populateMenuData();
		}, 120000);
	};

	useEffect(() => {
		populateMenu();

		setTimeout(() => {
			MenuService.populateMenuData();
		}, 2000);

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-celulas`}
					component={lazy(() => import(`./lista-celulas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/celula/:id`}
					component={lazy(() => import(`./celula`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/celula`}
					component={lazy(() => import(`./celula`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-regras`}
					component={lazy(() => import(`./lista-regras`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-regras-alertas`}
					component={lazy(() => import(`./lista-regras-alertas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-dados-extra/:id`}
					component={lazy(() => import(`./lista-dados-extra`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regra/:id`}
					component={lazy(() => import(`./regra`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regra`}
					component={lazy(() => import(`./regra`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-empresas`}
					component={lazy(() => import(`./lista-empresas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/empresa/:id`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/configuracoes-gerais`}
					component={lazy(() => import(`./configuracoes-gerais`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/execucao-rapida`}
					component={lazy(() => import(`./execucao-rapida`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/alertas-regra/:id`}
					component={lazy(() => import(`./alertas-regra`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/alertas-empresa/:id`}
					component={lazy(() => import(`./alertas-empresa`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
