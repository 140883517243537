const dev = {
	API_ENDPOINT_URL: "http://localhost:8093/contaseg/api",
	PUBLIC_PREFIX_PATH: "",
};

const prod = {
	API_ENDPOINT_URL: "https://lupa.contaseg.com.br/contaseg-back/api",
	PUBLIC_PREFIX_PATH: "",
};

const prod2 = {
	API_ENDPOINT_URL:
		"https://contaseg-back-eoi2e.ondigitalocean.app/contaseg-back2/contaseg-back/api",
	PUBLIC_PREFIX_PATH: "",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/contaseg-back-homolog/api",
	PUBLIC_PREFIX_PATH: "/contaseg",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "production2":
			return prod2;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();
